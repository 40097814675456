import { createContext, useContext } from 'react';
import createPersistedReducer from 'use-persisted-reducer';

const usePersistedReducer = createPersistedReducer('stateDisplay');

export const DisplayContext = createContext();

export function useDisplayContext(){
    return useContext(DisplayContext);
}

function reducer(state,action){
    switch(action.type){
        case 'updateKodeDisplay':
            return {...state,kodeDisplay:action.payload};
        case 'updateLogo':
            return {...state,logo:action.payload};
        default:
            throw new Error('unexpected action')
    }
}

const initialState = {
    kodeDisplay:"",
    logo:""
}
export function DisplayProvider({children}){
    const [state,dispatch]=usePersistedReducer(reducer,initialState);

    const displayContextValue = [state,dispatch];

    return (
        <DisplayContext.Provider value={displayContextValue}>
            {children}
        </DisplayContext.Provider>
    )
}
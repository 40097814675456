import { createContext, useContext } from "react";
import createPersistedReducer from 'use-persisted-reducer';

const usePersistedReducer = createPersistedReducer('stateUserRegister');

export const UserRegisterContext = createContext();

export function useUserRegisterContext() {
	return useContext(UserRegisterContext);
}

function reducer(state,action){
	switch(action.type){
		case 'changeRegister':
			return {...state,register:action.payload};
		default :
		throw new Error('unexpected action')
	}
}

const initialState = {
    register:{}
}

export function UserRegisterProvider({children}){
	const [state,dispatch]=usePersistedReducer(reducer,initialState);

	const userRegisterContextValue = [state,dispatch]

	return (
		<UserRegisterContext.Provider value={userRegisterContextValue}>
			{children}
		</UserRegisterContext.Provider>
	)
}

import { createContext, useContext } from 'react';
import createPersistedReducer from 'use-persisted-reducer';

const usePersistedReducer = createPersistedReducer('stateDashboard');

export const DashboardContext = createContext();

export function useDashboardContext(){
    return useContext(DashboardContext);
}

function reducer(state,action){
    switch(action.type){
        case 'updateImage':
            return {...state,imageProfile:action.payload};
        case 'updateTitlePage':
            return {...state,titlePage:action.payload};
        case 'updateRoleId':
            return {...state,roleId:action.payload};
        case 'updateRole':
            return {...state,role:action.payload};
        case 'updateIdTenantUser':
            return {...state,idTenantuser:action.payload};
        case 'updateBackgroundSidebar':
            return {...state,backgroundSidebar:action.payload};
        case 'updateUrl':
            return {...state,url:action.payload};
        case 'updateLogoTenant':
            return {...state,logoTenant:action.payload};
        default:
            throw new Error('unexpected action')
    }
}

const initialState = {
    titlePage:"",
    roleId:"",
    role:"",
    imageProfile:"",
    idTenantuser:"",
    backgroundSidebar:"",
    url:"",
    logoTenant:""
}

export function DashboardProvider({children}){
    const [state,dispatch]=usePersistedReducer(reducer,initialState);

    const dashboardContextValue = [state,dispatch];

    return (
        <DashboardContext.Provider value={dashboardContextValue}>
            {children}
        </DashboardContext.Provider>
    )
}